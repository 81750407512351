<template>
  <header class="header">
    <div class="header-left">
      <img class="logo" src="../assets/imgs/logo.png" alt="">
    </div>
    <div class="header-right">
      <router-link to="/">首页</router-link>
      <a class="content" href="javascript:;" >内容品牌
        <div class="qrcode-box">
          <div class="qrcode" v-for="item in list" :key="item.title">
            <img :src="item.img" alt="" class="qrcode-img">
            <div class="content-title">{{item.title}}</div>
          </div>
        </div>
      </a>
      <router-link to="/about">关于我们</router-link>
      <router-link to="/joinus">加入我们</router-link>
    </div>
  </header>
</template>

<script>
export default {
  data () {
    return {
      list: [
        { img: require('../assets/imgs/icon1.png'), title: '深燃' },
        { img: require('../assets/imgs/icon2.png'), title: '开菠萝财经' },
        { img: require('../assets/imgs/icon3.png'), title: '深燃视频' },
        { img: require('../assets/imgs/icon4.png'), title: '梦姐和菲哥' }
      ]
    }
  },
  components: {
  }
}
</script>

<style scoped>
.header{
  display: flex;
  justify-content: space-between;
}
.header-right{
  display: flex;
  width: 654px;
  justify-content: space-between;
  padding: 0px 226px 0 0 ;
}
.header a{
  display: inline-block;
  padding-top: 36px;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 22px;
}
.header a.router-link-exact-active {
    color: #F52727;
    position: relative;
}
.header a.router-link-exact-active::after{
  content: '';
  display: inline-block;
  width: 100%;
  height:  3px;
  background: #F52727;
  bottom: 0;
}
.logo{
  width: 120px;
  height: 105px;
  margin-left: 136px;
}
.content{
  position: relative;
  padding-left: 10px;
}
.content::after{
  content: '';
  display: inline-block;
  margin-left: 10px;
  width: 20px;
  height: 21px;
  background: url(../assets/imgs/arrow-down.png) no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
}
.content:hover .qrcode-box{
  display: flex;
}
.qrcode{
  flex: 1 1 25%;
}
.qrcode-box{
  display: none;
  padding: 28px 0 0;
  justify-content: space-between;
  position: absolute;
  background: #fff;
  top: 90%;
  right: -254px;
  height: 227px;
  width: 914px;
  z-index: 4;
  text-align: center;
}
.qrcode-img{
  width: 128px;
  height: 128px;
}
.content-title{
  text-align: center;
  /* display: inline-block; */
  margin-top: 12px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
}

</style>
