<template>
  <div class="home">
    <img class="slogan" src="../../assets/imgs/slogan.png" alt="">
    <img class="right-pic" src="../../assets/imgs/pic.png" alt="">
    <div class="triangle"></div>
    <div class="content-brands">
      <div class="content-brands-title">内容品牌</div>
      <div class="content-brands-en">Our Brands</div>
      <div class="content-one">
        <div class="content-one-img">
          <img src="https://file.imddoctors.com/20210608/1621916021934.png" alt="">
        </div>
        <div class="content-one-word">
          <div class="content-one-title">深燃</div><br/>
          <div class="content-one-intro">
            成立于2020年8月，是一个聚焦创新经济、专注优质内容的新媒体平台，以财经视角及时跟踪产业创新、深刻解读产业变革
          </div>
        </div>
      </div>
      <div class="content-three">
        <ContentCell v-for="item in list" :item="item" :key="item.title" />
      </div>
    </div>
    <div class="content-brands content">
      <div class="content-brands-title">获得奖项</div>
      <div class="content-brands-en">Our Awards</div>
      <div class="time-line" v-for="item in timeline" :key="item.content">
        <div class="time-line-left">{{item.time}}</div>
        <div class="time-line-right">{{item.content}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentCell from './ContentCell.vue'
export default {
  name: 'Home',
  data () {
    return {
      list: [
        { url: 'https://file.imddoctors.com/20210608/1621916062429.png', title: '开菠萝财经', intro: '深燃旗下垂直新媒体，聚焦直播电商、关注新消费' },
        { url: 'https://file.imddoctors.com/20210608/1621916093084.png', title: '深燃视频', intro: '深燃旗下短视频IP，关注财经热点、明星公司等' },
        { url: 'https://file.imddoctors.com/20210608/1621916112878.png', title: '梦姐和菲哥', intro: '深燃旗下短视频IP，探索奇妙世界，主打新品评测、新知趣事' }
      ],
      timeline: [
        { time: '2020/12', content: '36氪新经济十年一见商业洞察大奖' },
        { time: '2020/12', content: '钛媒体年度作者一鸣惊人奖' },
        { time: '2020/12', content: '大风号年度优质创作者' },
        { time: '2020/12', content: '网易号年度最具影响力奖' },
        { time: '2020/12', content: '一点号年度黑马作者' },
        { time: '2020/12', content: '新浪财经年度最佳创作力奖' },
        { time: '2020/12', content: '财经头条“智慧锋芒”奖年度最佳原创力' },
        { time: '2020/12', content: '新浪科技年度机构作者' }
      ]
    }
  },
  components: {
    ContentCell
  }
}
</script>
<style scoped>
.home{
  position: relative;
  padding: 0 135px;
  background: url(../../assets/imgs/wave-bj.png) no-repeat;
  background-size: 561px auto;
  background-position: right 1660px;
}
.slogan{
  margin: 155px 0 0 0;
  width: 482px;
  height: 191px;
}
.right-pic{
  position: absolute;
  pointer-events: none;
  width:771px;
  height: 945px;
  top: -109px;
  right: 0;
  z-index: 2;
}
.content-brands-title{
  padding: 105px 0 108px;
  margin-top: 521px;
  text-align: center;
  height: 64px;
  font-size: 56px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 64px;
}
.content .content-brands-title{
  margin-top: 197px;
}
.content-brands-en{
  margin-bottom: 100px;
  text-align: center;
  font-size: 24px;
  font-family: Futura-Book, Futura;
  font-weight: normal;
  color: #999999;
  line-height: 31px;
}
.content-one{
  width: 1170px;
  height: 368px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #E6EDF5;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
}
.content-one-img img{
  width: 554px;
  height: 336px;
  border-radius: 8px 8px 50px 8px;
  margin-right: 30px;
}
.content-one-title{
  margin-top: 81px;
  height: 48px;
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 48px;
}
.content-one-intro{
  height: 96px;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 32px;
}
.content-three{
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.time-line{
  padding-bottom: 77px;
  padding-left: 262px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.time-line-left{
  width: 221px;
  height: 32px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F52727;
  line-height: 32px;
  letter-spacing: 2px;
  position: relative;
  text-indent: 16px;
}
.time-line-left::before{
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width:8px;
  height: 8px;
  border-radius: 8px;
  background: #F52727;
  transform: translate(0, -50%);

}
.time-line-right{
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
}
.triangle{
  position: absolute;
  left: 0;
  top: 547px;
  /* width: 100%; */
  border: 150px solid #FFFFFF;
  border-left-color: transparent;
  border-top-color: transparent;
  transform: scaleX(4.8);
  transform-origin: 0 0;

}
</style>
