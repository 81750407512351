<template>
  <div class="content-cell">
    <img class="content-cell-img" :src="item.url" alt="">
    <div class="content-cell-title">{{item.title}}</div><br/>
    <div class="content-cell-intro">{{item.intro}}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data  () {
    return {
    }
  },
  components: {
  }
}
</script>

<style scoped>
.content-cell{
  width: 370px;
  height: 442px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #E6EDF5;
  padding: 16px;
  text-align: center;
}
.content-cell-img{
  width: 338px;
  height: 240px;
  border-radius: 8px 8px 50px 8px;
}
.content-cell-title{
  margin: 12px auto 6px;
  height: 32px;
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 32px;
}
.content-cell-intro{
  height: 64px;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 32px;
}
</style>
