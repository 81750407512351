<template>
  <div id="app">
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
*{
  box-sizing: border-box;
}
html, body {
  font-family: PingFangSC-Regular, PingFang SC;
}
body{
  background: #000;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  width: 1440px;
  margin: 0 auto;
  background: #fff url(./assets/imgs/main-bj.png) no-repeat;
  background-size: 100% auto;
}
@font-face {
  font-family: Futura;
  src:url("./assets/fonts/futura-book-2.ttf");
}
</style>
