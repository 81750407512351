<template>
  <footer class="footer">
    <div class="content">
      <div class="content-left">
        <img src="../assets/imgs/logo.png" alt="" class="main-logo">
        <div class="app-box">
          <div class="app-title">深燃APP</div>
          <div class="app-info">深度内容、效率阅读</div>
        </div>
      </div>
      <div class="content-right">
        <div class="qrcode">
          <img :src="require('../assets/imgs/icon-down.png')" alt="">
          <div class="qrcode-title">下载深燃APP</div>
        </div>
        <div class="qrcode">
          <img :src="require('../assets/imgs/icon1.png')" alt="">
          <div class="qrcode-title">关注深燃公众号</div>
        </div>
      </div>
    </div>
    <div class="info-box">
      <div class="info-box-left">
        <div class="bottom-nav-cell" @click="$router.push({path: 'about'})">关于我们</div>
        <div class="bottom-nav-cell" @click="goOther('https://api.shenrancaijing.com/shenran-content/#/user')">用户协议</div>
        <div class="bottom-nav-cell" @click="goOther('https://api.shenrancaijing.com/shenran-content/#/privacy')">隐私政策</div>
        <div class="info-title info-content address">公司地址：北京市朝阳区东三环南路98号</div>
      </div>
      <div class="info-box-right">
        <div class="info-title">爆料：</div>
        <div class="info-title">商务合作：</div>
        <div class="info-title">服务电话：</div>
        <div class="info-title info-content">shenranbd@163.com</div>
        <div class="info-title info-content">shenranbd@163.com</div>
        <div class="info-title info-content">010-58611268</div>
      </div>
    </div>
    <div class="footer-bottom">
      Copyright@2021 北京艾莉亚信息科技有限公司
      <span class="icp" @click="goOther('https://beian.miit.gov.cn/#/Integrated/index')">京ICP备2021017759号-1</span>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  },
  methods: {
    goOther (url) {
      location.href = url
    }
  }
}
</script>

<style scoped>
.icp{
  cursor: pointer;
}
.footer{
  background: #000;
  padding: 0 135px;
  height: 663px;
  padding-top: 147px;
}
.content{
  /* margin-top: 147px; */
  display: flex;
  justify-content:space-between;
}
.content-left{
  display: flex;
  padding-top: 4px;
}
.content-right{
  display: flex;
  justify-content: flex-end;
  padding-right: 23px;
}
.app-title{
  color:#FFFFFF;
  line-height: 78px;
  font-size: 56px;
}
.main-logo{
  width: 128px;
  height: 128px;
  margin-right: 54px;
  border: 2px solid #FFFFFF;
  border-radius: 24px;
}
.app-info{
  margin-top: 12px;
  width: 270px;
  height: 42px;
  font-size: 30px;
  font-weight: 400;
  color: #B1B1B1;
  line-height: 42px;
}
.qrcode{
  margin-left: 40px;
  text-align: center;
}
.qrcode img{
  width: 135px;
  height: 135px;
}
.qrcode-title{
  margin-top: 25px;
  text-align: center;
  width: 168px;
  height: 33px;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
}
.info-box{
  margin-top: 80px;
}
.info-box-left{
  text-align: left;
}
.bottom-nav-cell{
  cursor: pointer;
  display: inline-block;
  height: 32px;
  font-size: 22px;
  color: #FFFFFF;
  line-height: 32px;
  padding: 0 20px;
  border-right: 1px solid #Fff;
}
.bottom-nav-cell:nth-child(1){
 padding-left: 0;
}
.bottom-nav-cell:nth-child(3){
  border: none;
}
.info-box{
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255,0.15);
}
.info-box-right{
  width: 596px;
}
.info-title{
  font-size: 22px;
  color: #FFFFFF;
  line-height: 32px;
  display: inline-block;
  width: 33.3%;
}
.info-content{
  margin-top: 16px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 32px;
}
.address{
  width:100%;
}
.footer-bottom{
  text-align: left;
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 32px;
}
</style>
